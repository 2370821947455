@import "variables";
@import "common";

.product {
  @extend .font__content;

  .ui.items>.item {
    @extend .padding__content;
  }

  .product__item__HR {
    // border-top: 3px solid $grey !important;
    // border-bottom: 3px solid $grey !important;
  }


  .ui.items>.item>.content {
    @extend .flex__content;
  }

  .ui.items>.item>.content>.description {
    text-align: left;
  }

  .ui.items>.item>.image {
    margin: auto !important;
  }

  .product__container {
    @extend .container;
  }

  .product__title {
    @extend .title
  }

  .product__name {
    color: $title-green !important;
    font-weight: bold;
  }

  .product__description>div {
    padding: 1% 0;
  }
}