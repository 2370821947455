
// COLORS
$light-green: #9fcaba;
$green: #32754c;
$title-green: #569f85;
$blog-title: #3F695A;
$blog-time-color: #FD9C43;
$grey-green:#d5e2dc;

$grey: #dbdddc;
$white: #fff;


// FONT-SIZES
$h2: 24;

// SCREEN-SIZES
$small: 375;
$medium: 600;
$large: 1500;
