.blog-item-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 336px;
    height: 277px;
    margin: 10px 10px;
    cursor: pointer;
    .blog-image {
        width: 336px;
        height: 189px;
    }
    .blog-title {
        font-size: 16px;
        text-align: left;
        margin-top: 5px;
        margin-bottom: 5px;
        color: #3F695A
    }
    .blog-content {
        font-size: 12px;
        color: #000000A6;
    }
}