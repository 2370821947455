@import 'variables';
@import 'common';

.blog-detail-page {
    @extend .font__content;

  .blog__detail__container {
    margin: 20px 25% 0 10%;
    text-align: left;
    .blog-title {
        color: $blog-title;
        font-size: 28px;
        line-height: 32px;
        font-weight: 700;
        margin: 25px 0 8px;
        text-align: left;
        line-height: 28px;
    }
    .blog-time {
        color: $blog-time-color;
        font-size: 14px;
        margin-bottom: 20px;
        font-weight: 600;
    }
    .blog-main-pic {
        border-radius: 15px;
        margin-bottom: 20px;
    }
    .blog-paragraph {
        font-size: 16px;
        margin-bottom: 20px;
    }
    .blog-bold-p {
        font-weight: 600;
        margin-bottom: 0;
    }
    .blog-sub-title {
        color: $blog-title;
        font-size: 20px;
        font-weight: 600;
    }
    .blog-ol {
        li {
            margin-bottom: 10px;
        }
    }
    .blog-image-container {
        display: flex;
        width: auto;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        // div {
        //     display: flex;
        //     padding: 6px 10px;
        //     border-radius: 5px;
        // }
    }
    .blog-table {
        border-collapse: collapse;
        width: 100%;
        margin-bottom: 20px;
        td, th {
            border: 1px solid #dddddd;
            text-align: center;
            padding: 8px;
        }
        tr.blog-table-title {
            th {
                background-color: #ee4d2d !important;
                text-align: center;
                color: white;
                font-weight: 700;
            }
        }
        td {
            min-width: 160px
        }
    }
  }
}

.blog-iframe {
  width: 100%;
  border: none;
  overflow: hidden; // 隐藏滚动条
  display: block;
  margin: 0 auto;
}

.loading-indicator {
  text-align: center;
  padding: 2rem;
  font-size: 1.2rem;
}