@import 'variables';
@import 'common';

.blog {
  @extend .font__content;

  .blog__container {
    @extend .container;
  }
  .blog__mission {
    @extend .mission__content;
    justify-content: space-between;
    margin-left: 10%;
    margin-right: 10%;

    @media only screen and (max-width: #{$medium}px) {
      flex-direction: column;
      align-items: flex-start;
    }
  
    @media only screen and (max-width: #{$small}px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  .blog-main-title {
    color: $blog-title;
    font-size: 36px;
    line-height: 40px;
    font-weight: 700;
    text-align: left;
    margin: 20px 0 10px 10%;
  }
  .blog-sub-title {
    color: $blog-time-color;
    font-size: 20px;
    line-height: 24px;
    margin: 10px 0 10px 10%;
    text-align: left;
  }
}
